.slick-dots {
  position: absolute;
  bottom: -10px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  margin: 0 2px; /* Adjusted margin for better spacing */
}

.slick-dots li button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  color: black;
}

.slick-dots li button:before {
  font-size: 12px;
  color: gray;
}

.image-container {
  position: relative;
}

.left-dots {
  position: relative;
  /* top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px; */
}

.left-dots .dot {
  /* width: 16px;
  height: 16px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; */
  /* transition: transform 0.25s ease-in-out, background 0.25s ease-in-out; */
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 10px #00000040;
  cursor: pointer;
  z-index: 1;
  transform: scale(1);
  transition: transform 0.25s ease-in-out;
}

.left-dots .dot::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  animation: dotPulse 1.4s ease-in-out infinite;
  left: -12px;
  top: -12px;

  /* position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  left: -12px;
  top: -12px;
  border-radius: 100%;
  background: #fff6; */
  /* -webkit-animation: 1.4s shopTheLookDotKeyframe ease-in-out infinite; */
  /* animation: 1.4s shopTheLookDotKeyframe ease-in-out infinite; */
}

@keyframes dotPulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.custom-dot {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin: 0 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-dot span {
  display: none;
}

.custom-dot.slick-active {
  width: 15px;
  height: 15px;
  background: rgba(0, 0, 0, 0.6);
}

.slick-dots .slick-active .custom-dot {
  background: rgba(0, 0, 0, 0.6);
}

.left-dots .dot.active {
  /* background: rgba(0, 0, 0, 0.6); */
  transform: scale(1.5);
}

.left-dots .dot:hover {
  /* background: rgba(0, 0, 0, 0.6); */
  transform: scale(1.5);
}
.modal-backdrop {
  background: transparent;
}
.slide-up-modal {
  overflow: hidden;
  padding: 0px !important;
  touch-action: manipulation;

  .modal-dialog {
    width: 100%;
    min-width: 100%;
    margin: 0px;
    height: 50%;
    transform: translateY(55%) !important;

    .slick-slider {
      width: 240px !important;
    }
    .slick-dots {
      bottom: -15px !important;
    }
  }
  .modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .modal-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.our-look-slider {
  /* height: 550.634px; */
  /* transform: translate3d(0px, 109px, 0px) scale(2.72752); */
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.look-slider {
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.mobile-side-bar-list {
  color: #14344a;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.12px;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}

.slick-dots ul{
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots ul li{
  display: flex;
  justify-content: center;
  align-items: center;
}