.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 90px; /* Base width for small screens */
  font-size: 16px; /* Base font size */
  z-index: 999999999;
}

.custom-select {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.custom-select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 0px;
  color: white;
  border-radius: 4px;
}

.custom-select-trigger img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100%;
  object-fit: cover;
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: none;
}

.custom-select:hover .custom-options {
  display: block;
}

.custom-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.custom-option img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100%;
  object-fit: cover;
}

.custom-option:hover {
  background: #f0f0f0;
}

/* Breakpoint-specific font-size and width adjustments */

@media screen and (min-width: 2299px) {
  .custom-select-wrapper {
    width: 200px;
    font-size: calc(1rem + 0.3vw);
  }
  .custom-select-trigger img,
  .custom-option img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1499px) and (max-width: 2298px) {
  .custom-select-wrapper {
    width: 160px;
    font-size: calc(1rem + 0.2vw);
  }
  .custom-select-trigger img,
  .custom-option img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 1498px) {
  .custom-select-wrapper {
    width: 130px;
    font-size: calc(1rem + 0.1vw);
  }
  .custom-select-trigger img,
  .custom-option img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 991px) {
  .custom-select-wrapper {
    width: 110px;
    font-size: calc(0.7rem + 0.5vw);
  }
  .custom-select-trigger img,
  .custom-option img {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 576px) {
  .custom-select-wrapper {
    width: 90px;
    font-size: calc(0.7rem + 0.3vw);
  }
  .custom-select-trigger img,
  .custom-option img {
    width: 20px;
    height: 20px;
  }
}
