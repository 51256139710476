.buy-ring-size table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.buy-ring-size {
  font-size: 14px;
  line-height: normal;
}
.buy-ring-size td,
.buy-ring-size th {
  border: 1px solid #d4d4d4;
  padding: 9px 10px;
  text-align: center;
}
.buy-ring-size th {
  font-weight: bold;
}
.buy-ring-size p {
  letter-spacing: 0.025em;
}
.buy-ring-size .table-wrapper {
  position: relative;
  overflow: hidden;
  border-right: 1px solid #ccc;
}
.buy-ring-size .scrollable {
  overflow: scroll;
  overflow-y: hidden;
}
@media (max-width: 767px) {
  .buy-ring-size .responsive {
    margin-bottom: 0;
  }
  .buy-ring-size .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
  }
  .buy-ring-size .pinned-table-header {
    display: block;
    width: 154px;
    white-space: normal;
  }
  .buy-ring-size .large-table-header {
    display: block;
    width: 200px;
    white-space: normal;
  }
  .buy-ring-size .medium-table-header {
    display: block;
    width: 120px;
    white-space: normal;
  }
}
