/* .custom-tab .nav-link {
  color: #000; 
}
.custom-tab .nav-link:hover {
  color: #000 !important;
}

.custom-tab .nav-tabs:hover {
  color: #000 !important;
} */
.hero-section {
  position: relative;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
  z-index: -1; /* Places the video behind other content */
}

.custom-banner-home {
  height: 500px;
  width: 100%;
}

.custom-banner-home2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.review-card {
  min-height: 400px;
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis-text-button {
  margin-top: 5px;
}

.main-color {
  padding: 1px;
  line-height: 0;
  border-radius: 50%;
  border: 1px solid transparent;
}

.color-select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 10px 0px;
}

.color-circle {
  border-radius: 50%;
  /* padding: 2px; */
  height: 20px;
  width: 20px;
  overflow: hidden;
}

.main-color.selected {
  border: 1px solid black;
}
.not-allowed {
  cursor: not-allowed;
}

.wishlist-icon {
  /* transition: color 0.3s;  */
  font-size: 22px;
  color: #e77e50;
  position: relative;
  z-index: 1;
}
.wishlist-icon-start {
  /* transition: color 0.3s;  */
  font-size: 22px;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

#hlist-icon:hover {
  color: red; /* Change this to any color you want */
}

.wishlist-icon:hover {
  color: #e77e50 !important; /* Change this to any color you want */
}
.btn-heart {
  div {
    position: absolute;
    /* top: 24%;
    right: 0%; */
    top: 20%;
    right: -5%;
    font-size: 0.4625em;
    margin: 0;
    z-index: 2;
    width: 22px;
    text-align: center;
  }
  p {
    margin: 0;
  }
  /* p {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 0.5625em;
    margin: 0;
    z-index: 2;
  } */
  .border-heart {
    display: block;
  }
  .fill-heart {
    display: none;
  }
}
.btn-heart:hover {
  .border-heart {
    display: none;
  }
  .fill-heart {
    display: block;
  }
}

.user-icon {
  /* transition: color 0.3s; */
  margin-top: 1px;
  .border-heart {
    /* width: 22px; */
    font-size: 22px;
    display: contents;
  }
  .fill-heart {
    /* width: 22px; */
    font-size: 22px;

    display: none;
  }
}

.user-icon:hover {
  .border-heart {
    display: none;
  }
  .fill-heart {
    display: contents;
  }
}
/* .fill-heart {
  display: block;
}
.fill-heart:hover {
  display: none;
}
.border-heart {
  display: none;
}
.border-heart:hover {
  display: block;
} */

.animated-icon {
  animation: sizeAnimation 1.5s infinite alternate-reverse;
}

@keyframes sizeAnimation {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.elegant-container {
  /* h2 { */
  /* color: black; */
  /* font-family: Lora; */
  /* font-size: 44px; */
  /* font-style: normal; */
  /* font-weight: 400; */
  /* line-height: 56px;
    margin-bottom: 20px;
    margin-top: 60px;
    text-align: center; */
  /* } */

  /* p {
    color: black;
    font-family: Gantari;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: auto auto 64px;
   text-align: center;
  } */
}

@media (max-width: 991.98px) {
  /* md and smaller screens */
  .elegant-main-img {
    aspect-ratio: unset;
    height: auto;
  }
  .elegant-img {
    width: 100%;
    aspect-ratio: 325/270;
    object-fit: cover;
    height: auto;
  }
}

/* Home.css */
.elegant-container {
  text-align: center;
}

.custom-slider {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  overflow-x: auto; /* Ensure horizontal scrolling is enabled */
  padding-bottom: 15px;
  padding-left: 12px;
  padding-right: 0;
  scroll-snap-type: x mandatory;
}

.custom-slider-item {
  flex: 0 0 auto;
  width: 300px; /* Adjust as needed */
  margin-right: 12px;
  scroll-snap-align: start;
}

.custom-slider-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.custom-slider::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.custom-slider:active {
  cursor: grabbing;
}

/* .my-6 { */
/* margin-top: 3rem;
  margin-bottom: 3rem; */
/* } */

.my-7 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sub-line {
  margin-bottom: 2rem;
}

.elegant-img {
  width: 100%;
  aspect-ratio: 330/314;
  object-fit: cover;
  height: auto;
}

.elegant-main-img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
  /* height: 82%; */
}

/* HorizontalScroll.css */
.scroll-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scroll-container {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.scroll-container img {
  height: 100%;
  width: auto;
  display: inline-block;
}

.get-inspired .slick-next {
  height: 100%;
  width: 40px;
  visibility: visible;
  opacity: 1;
  background: rgba(255, 255, 255, 0);
  top: 0;
  transform: none;
  z-index: 2;
  transition: all 0.3s;
}

.get-inspired .slick-prev {
  height: 100%;
  width: 40px;
  visibility: visible;
  opacity: 1;
  background: rgba(255, 255, 255, 0);
  top: 0;
  transform: none;
  z-index: 2;
  transition: all 0.3s;
}

.get-inspired .carousole-photo {
  position: relative;
}

.get-inspired .carousole-photo .overlay {
  position: absolute;
  color: white;
  top: 0;
  background: #000000cc;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.get-inspired .carousole-photo:hover .overlay {
  visibility: visible;
  opacity: 1;
}

.get-inspired .slick-prev,
.get-inspired .slick-next {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.get-inspired:hover .slick-prev,
.get-inspired:hover .slick-next {
  visibility: visible !important;
  opacity: 1 !important;
  background-color: #ffffffd6 !important;
}

.drop-hint-modal {
}

.drop-hint-modal .drop-hint-modal-header {
  h3 {
    color: #23351d;
    font-family: "EB Garamond";
    font-size: 36px;
    padding-bottom: 0;
    text-transform: none;
    line-height: 1.3em;
  }
}

.drop-hint-modal .drop-hint-modal-dialog {
  width: 600px;
  min-width: 600px;
}

@media (max-width: 767px) {
  /* md and smaller screens */
  .drop-hint-modal .drop-hint-modal-dialog {
    width: auto;
    min-width: auto;
    margin: 10px;
  }
}

.drop-hint-modal .drop-hint-modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.campaign-modal {
  /* display: none;
  opacity: 0; */
  min-height: 100vh;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  display: flex;
}

.campaign-modal .campaign-content {
  background-color: initial;
  margin: auto;
  position: relative;
  height: 520px;
  width: 520px;
  color: #fff;
  background-size: cover;
}

.campaign-modal .campaign-sidepart {
  padding: 0rem 0rem 0rem calc(50%);
  display: flex;
  flex-direction: column;
  height: 520px;
}

.campaign-modal .campaign-sidepart > div:first-child {
  height: 520px;
}

@media screen and (max-width: 498px) {
  .campaign-modal .campaign-sidepart {
    padding: 0rem 0rem 0rem 0rem;
  }
}

/* @media screen and (max-width: 504px) {
  .campaign-modal .campaign-sidepart {
    padding: 0rem 0rem 0rem 0px;
  }
  .campaign-modal .campaign-sidepart > div:first-child {
    padding: 5.2rem 1rem 5.2rem !important;
  }
}
@media screen and (max-width: 436px) {
  .campaign-modal .campaign-sidepart {
    padding: 0rem 0rem 0rem 0px;
  }
  .campaign-modal .campaign-sidepart > div:first-child {
    padding: 5.2rem 1rem 5.2rem !important;
  }
} */

@media screen and (max-width: 490px) {
  .campaign-modal .campaign-sidepart {
    padding: 0rem 0rem 0rem 0rem;
  }
  /* .campaign-modal .campaign-sidepart > div:first-child {
    padding: 4.8rem 1rem 4.8rem !important;
  } */
}

@media screen and (max-width: 380px) {
  .campaign-modal .campaign-sidepart {
    padding: 0rem 0rem 0rem 0rem;
  }
  /* .campaign-modal .campaign-sidepart > div:first-child {
    padding: 4.8rem 1rem 4.8rem !important;
  } */
}

.campaign-modal
  .campaign-content
  .campaign-sidepart
  .campaign-sidepart-top
  > p:first-child {
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  margin: 0;
  margin-bottom: -10px;
}

.campaign-modal
  .campaign-content
  .campaign-sidepart
  .campaign-sidepart-top
  > p:last-child {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  margin: 0;
}

.campaign-modal
  .campaign-content
  .campaign-sidepart
  .campaign-sidepart-top
  > p:first-child
  > span {
  font-weight: 400;
  font-size: 46px;
}

.campaign-modal
  .campaign-content
  .campaign-sidepart
  .campaign-sidepart-middle
  > p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 20px;
}

.campaign-sidepart-middle {
  text-align: center;
  padding: 30px 0;
}

.campaign-sidepart-disclaimer {
  font-size: 10px;
  line-height: 12px;
  padding: 20px 0;
  text-align: justify;
  color: #ffffff;
}

.campaign-sidepart-disclaimer a:first-child {
  text-decoration: underline;
  color: inherit;
}

.campaign-sidepart-disclaimer a:not(:first-child) {
  color: #c7ced3;
}

.campaign-modal input {
  padding: 10px 18px;
  width: 100%;
}

.campaign-modal .campaign-content .button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
}

.campaign-modal .button-wrapper button:first-child {
  background: #fff;
  border-radius: 0;
  border: 0px;
  color: #e77e50;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  cursor: pointer;
  height: 35px;
  width: 50%;
}

.campaign-modal .button-wrapper button:last-child {
  background: rgba(255, 255, 255, 2%);
  border: 1px solid #fff;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  padding: 0 10px;
  cursor: pointer;
  height: 35px;
  min-width: 78px;
}
