/*===== Contact header =====*/
.contact-header {
  padding-top: 65px;
}
.contact-header .section-header {
  max-width: 880px;
  margin: 0 auto 65px;
}
@media (max-width: 749px) {
  .contact-header {
    padding-top: 30px;
  }
  .contact-header .section-header {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

/*===== Contact form =====*/
.contact-form-section .contact-form-wrapper {
  background: #ffede4;
  max-width: 860px;
  margin: 0 auto;
  padding: 40px 10%;
}
.contact-form-section input,
.contact-form-section select,
.contact-form-section textarea {
  background-color: transparent;
}
@media (min-width: 750px) {
  .contact-form-section .form-wrapper {
    max-width: 500px;
    margin: 40px auto 0;
  }
}
@media (max-width: 749px) {
  .contact-form-section .contact-form-wrapper {
    padding: 20px;
  }
  .contact-form-section .contact-form {
    margin-top: 20px;
  }
}

/*===== Contact content =====*/
.text-content {
  max-width: 68%;
  margin: 0 auto;
}
@media (max-width: 749px) {
  .text-content {
    max-width: 88%;
  }
}
