@media (max-width: 991px) {
  .sb-active.sb-active-left {
    overflow: hidden;
    height: 100%;
  }
  #sb-site {
    -webkit-transition: -webkit-transform 0.4s ease;
    -moz-transition: -moz-transform 0.4s ease;
    -o-transition: -o-transform 0.4s ease;
    transition: transform 0.4s ease;
    -webkit-transition-property: -webkit-transform, left, right;
    -webkit-backface-visibility: hidden;
    background: #fcf9f0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
  .sb-site-container,
  .sb-slide,
  .sb-slidebar {
    -webkit-transition: -webkit-transform 0.4s ease;
    -moz-transition: -moz-transform 0.4s ease;
    -o-transition: -o-transform 0.4s ease;
    transition: transform 0.4s ease;
    -webkit-transition-property: -webkit-transform, left, right;
    -webkit-backface-visibility: hidden;
    background: #fcf9f0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
  .sb-active.sb-active-left #sb-site:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .sb-slidebar.sb-active {
    background-color: #fcf9f0;
  }
}

.bg-white,
.bg-white #sb-site {
  background-color: #ffffff;
}
.bg-cream,
.bg-cream #sb-site {
  background-color: #fffefc;
}

.popup-cls #sb-site {
  height: 100vmax;
}

.landing-page .common-banner .content .miska-logo {
  max-width: 280px;
}

.landing-page .common-banner .container {
  max-width: 100%;
  margin-left: 60px;
}
.landing-page .common-banner.right-text-banner .container {
  margin-left: 0px;
  margin-right: 60px;
}
.landing-page .common-banner .content .common-hd {
  color: #2b2b2b;
  font-size: 50px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}
.landing-page .common-banner .content .common-hd p {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-family: "Figtree", sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.landing-page .common-banner .content,
.landing-page .common-banner.left-text-banner .content {
  width: 100%;
  max-width: 675px;
}
.landing-page .common-banner .content p {
  color: #2b2b2b;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.landing-page .common-banner .content .btn,
.landing-page .common-banner.left-text-banner .content .btn,
.landing-page .common-banner.right-text-banner .content .btn {
  font-size: 13px;
  font-weight: 500;
  width: auto;
  height: 50px;
  padding: 10px 40px;
  line-height: 27px;
}
.landing-page .common-banner .content .btn:hover {
  background-color: #2b2b2b;
  color: #ffffff;
  border-color: #2b2b2b;
}

.landing-page.gift-guide .common-banner.left-text-banner .content {
  width: 350px;
}

.landing-page .common-banner .content p.disclaimer-text {
  font-size: 10px;
  line-height: 12px;
  max-width: 226px;
  text-align: center;
  margin: 10px 0px 0px;
}
.listingpage p.disclaimer-text {
  font-size: 14px;
  line-height: 20px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  color: #2b2b2b;
}

@media (min-width: 768px) {
  .landing-page .common-banner .text-white .common-hd,
  .landing-page .common-banner .text-white p,
  .landing-page .common-banner .text-white .btn_link {
    color: #ffffff;
  }
  .landing-page .common-banner .text-white .btn {
    border-color: #ffffff;
    color: #ffffff;
  }
  .landing-page .common-banner .text-white .btn.btn-primary {
    border-color: #9e371e;
    color: #ffffff;
  }
  .landing-page .common-banner .text-white .btn:hover {
    border-color: #2b2b2b;
  }
}

@media (max-width: 1600px) {
  .landing-page .common-banner .container {
    margin-left: 20px;
  }
  .landing-page .common-banner .content,
  .landing-page .common-banner.left-text-banner .content {
    max-width: 420px;
  }
  .landing-page .common-banner .content .common-hd {
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 15px;
  }
  .landing-page .common-banner .content p {
    font-size: 17px;
    line-height: 25px;
  }
  .landing-page .common-banner .content .common-hd p {
    margin-bottom: 15px;
  }
}

@media (max-width: 1366px) {
  .landing-page .common-banner .content .common-hd p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1199px) {
  .landing-page .common-banner .content .common-hd {
    font-size: 22px;
    line-height: 24px;
  }
  .landing-page .common-banner .container {
    margin-left: 5px;
  }
  .landing-page .common-banner .content,
  .landing-page .common-banner.left-text-banner .content {
    max-width: 345px;
  }
  .landing-page.gift-guide .common-banner.left-text-banner .content {
    width: 350px;
  }
  .landing-page .common-banner .content p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .landing-page .common-banner .content .common-hd {
    font-size: 18px;
    line-height: 18px;
  }
  .landing-page .common-banner.right-text-banner .container {
    margin-right: 30px;
  }
  .landing-page.gift-guide .common-banner.left-text-banner .content {
    width: 300px;
  }
  .landing-page .common-banner .content,
  .landing-page .common-banner.left-text-banner .content {
    max-width: 260px;
  }
  .landing-page .common-banner .content p {
    font-size: 12px;
    line-height: 18px;
  }
  .landing-page .common-banner .content .btn,
  .landing-page .common-banner.left-text-banner .content .btn,
  .landing-page .common-banner.right-text-banner .content .btn {
    height: 40px;
    line-height: 15px;
    margin-top: 5px;
    padding: 10px 30px;
  }
  .landing-page .common-banner .content p.disclaimer-text {
    max-width: 206px;
  }
}

@media (max-width: 767px) {
  .landing-page .common-banner .container {
    margin-left: 0px;
  }
  .landing-page .common-banner.right-text-banner .container {
    margin-right: 0px;
  }
  .landing-page .common-banner .content,
  .landing-page .common-banner.left-text-banner .content {
    max-width: 100%;
    text-align: center;
  }
  .landing-page .common-banner .content .common-hd {
    font-size: 18px;
    line-height: 22px;
    word-break: break-word;
    color: #2b2b2b;
  }
  .landing-page .common-banner .content p {
    color: #2b2b2b;
    font-size: 14px;
    line-height: 17px;
  }
  .landing-page.gift-guide .common-banner.left-text-banner .content {
    width: 100%;
  }
  .landing-page .common-banner .content .btn,
  .landing-page .common-banner.left-text-banner .content .btn,
  .landing-page .common-banner.right-text-banner .content .btn {
    font-size: 12px;
    height: 35px;
    line-height: 23px;
    padding: 5px 30px;
  }
  /*.landing-page .thumb{min-height: 220px;}*/
}

/*@media(min-width:768px){
.landing-page.gift-guide .common-banner.left-text-banner .content{width:300px;}
.landing-page.gift-guide .common-banner.left-text-banner .btn{width:auto;padding:10px 41px;line-height:25px;}
}*/

@media (max-width: 1199px) {
  .common-banner.left-text-banner .content,
  .common-banner .content {
    width: 400px;
  }
  .common-banner .content .common-hd {
    /*font-size:35px;line-height:40px;*/
    margin-bottom: 10px;
  }
  .common-banner.left-text-banner .content .btn {
    width: 250px;
    margin-top: 15px;
  }
  .common-banner.right-text-banner .content .btn {
    width: 250px;
    margin-top: 15px;
  }
  .landing-page .common-banner .content .miska-logo {
    max-width: 180px;
  }
}

@media (max-width: 991px) {
  .common-banner.left-text-banner .content .btn {
    line-height: 35px;
  }
  .common-banner.right-text-banner .content .btn {
    line-height: 35px;
  }
  .common-banner .content,
  .common-banner.left-text-banner .content {
    width: 350px;
  }
  .common-banner .common-hd {
    margin-bottom: 10px;
  }
  .common-banner .text-white .btn_link {
    margin-top: 15px;
  }
  .common-banner .content .common-hd {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .common-banner .content .common-hd {
    font-size: 18px;
    line-height: 22px;
    color: #2b2b2b;
  }
  .common-banner .container {
    position: initial;
    border-bottom: 1px solid #f1eeed;
  }
  .common-banner .content,
  .common-banner.center-text-banner .content {
    position: initial;
    transform: none;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 20px;
  }
  .common-banner .content p {
    font-size: 14px;
    line-height: 17px;
  }
  .common-banner .content .btn {
    width: auto;
    display: inline-block;
    padding: 5px 25px;
  }
  .common-banner .content,
  .common-banner.left-text-banner .content {
    width: 100%;
  }
  .no-padding {
    padding: 0px;
  }

  .common-banner.left-text-banner .content .btn.btn-style1 {
    width: auto;
  }
  .common-banner.right-text-banner .content .btn.btn-style1 {
    width: auto;
  }

  .common-banner .text-white .common-hd {
    color: #2b2b2b;
  }
  .common-banner .text-white p {
    color: #2b2b2b;
  }

  /*.landing-page .common-banner .container{position:absolute;border-bottom:none;}
.landing-page .common-banner .content{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);margin:0px;padding:0px 10px;}
.landing-page .common-banner .content h1, .landing-page .common-banner .content p{color:#F1EEED;}
.landing-page .common-banner .content.text-white h1, .landing-page .common-banner .content.text-white p{color:#ffffff;}
.landing-page .common-banner .content.text-green h1, .landing-page .common-banner .content.text-green p{color:#2B2B2B;}*/

  .common-banner.left-text-banner .content .btn {
    width: 220px;
    display: inline-block;
    margin-top: 15px;
    height: 40px;
    line-height: 29px;
    font-size: 12px;
  }
  .common-banner.right-text-banner .content .btn {
    width: 220px;
    display: inline-block;
    margin-top: 15px;
    height: 40px;
    line-height: 30px;
  }

  .landing-banner1 .common-banner .container {
    position: absolute;
    border: none;
  }
  .landing-banner1 .common-banner .content {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    padding: 0px 10px;
  }
  .landing-banner1 .common-banner .content .btn {
    width: 200px;
    display: inline-block;
  }

  /*.landing-page.bracelet-jewelry-main .common-banner .content h1{color:#9E371E;}
.landing-page.bracelet-jewelry-main .common-banner .content p{color:#808381;}*/

  .landing-page .common-banner .content p.disclaimer-text {
    max-width: 100%;
  }

  .ttw_banner.common-banner .text-white .common-hd {
    color: #2b2b2b;
  }
  .ttw_banner.common-banner .text-white p {
    color: #2b2b2b;
  }
  .faq-que h3 {
    font-size: 18px;
    line-height: 22px;
  }
}

/*About Us new*/
.aboutus-new-main .about-discription {
  max-width: 90%;
}
.aboutus-new-main .about-discription .common-hd1 {
  font-size: 34px;
  line-height: 49px;
}
.aboutus-new-main .lft-right-section .common-hd {
  font-size: 50px;
  line-height: 52px;
}
.aboutus-new-main .lft-right-section p {
  margin-bottom: 15px;
}
.aboutus-new-main .lft-right-section p:last-child {
  margin-bottom: 0px;
}
.aboutus-new-main .about-option-main {
  margin-left: -100px;
  margin-right: -100px;
}
.aboutus-new-main .about-option-main li {
  color: #9e371e;
  font-family: Castoro;
  font-weight: 400;
  padding: 40px 100px;
}
.aboutus-new-main .ourstory-main .thumb img {
  max-width: 80%;
}
.aboutus-new-main .ourstory-main .thumb {
  position: relative;
  z-index: 1;
}
.aboutus-new-main .ourstory-main .content {
  border: 1px solid #9e371e;
  position: relative;
  margin-top: -220px;
  padding: 320px 120px 100px 120px;
}
.aboutus-new-main .ourstory-main .content-detail p {
  font-weight: 400;
  margin-bottom: 20px;
}
.aboutus-new-main .ourstory-main .content-detail p:last-child {
  margin-bottom: 0px;
}
.aboutus-new-main .shop-about-main {
  margin-left: -10px;
  margin-right: -10px;
}
.aboutus-new-main .shop-about-main .shop-about-inner {
  padding-left: 10px;
  padding-right: 10px;
}
.aboutus-new-main .shop-about-main .btn_link {
  position: absolute;
  left: 40px;
  bottom: 30px;
  color: #ffffff;
  font-weight: 700;
}
.aboutus-new-main .gift-guide-section .shop-style-inner:hover .thumb img {
  transform: none;
}

@media (min-width: 768px) {
  .aboutus-new-main .ourstory-main .common-hd {
    width: 34%;
  }
  .aboutus-new-main .ourstory-main .content-detail {
    width: 50%;
  }
  .aboutus-new-main .about-option-main li:not(:last-child) {
    border-right: 1px solid #9e371e;
  }
}

@media (min-width: 992px) {
  .aboutus-new-main .lft-right-section .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .aboutus-new-main .lft-right-section .row > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1600px) {
  .aboutus-new-main .ourstory-main .content-detail p {
    font-size: 26px;
    font-weight: 400;
    line-height: 39px;
    margin-bottom: 40px;
    margin-bottom: 40px;
  }
  .aboutus-new-main .lft-right-section .row {
    margin-left: -60px;
    margin-right: -60px;
  }
  .aboutus-new-main .lft-right-section .row > div {
    padding-left: 60px;
    padding-right: 60px;
  }
  .aboutus-new-main .shop-about-main .btn_link {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 1600px) {
  .aboutus-new-main .about-discription .common-hd1 {
    font-size: 26px;
    line-height: 40px;
  }
  .aboutus-new-main .lft-right-section .common-hd,
  .aboutus-new-main .ourstory-main .common-hd {
    font-size: 36px;
    line-height: 38px;
  }
  .aboutus-new-main .about-option-main {
    margin-left: -50px;
    margin-right: -50px;
  }
  .aboutus-new-main .about-option-main li {
    padding: 25px 50px;
    font-size: 26px;
    line-height: 28px;
  }
  .aboutus-new-main .ourstory-main .content {
    margin-top: -150px;
    padding: 270px 120px 120px 120px;
  }
}

@media (max-width: 1366px) {
  .aboutus-new-main .about-discription .common-hd1 {
    font-size: 20px;
    line-height: 30px;
  }
  .aboutus-new-main .about-option-main {
    margin-left: -30px;
    margin-right: -30px;
  }
  .aboutus-new-main .about-option-main li {
    padding: 25px 30px;
  }
  .aboutus-new-main .ourstory-main .content {
    margin-top: -150px;
    padding: 220px 70px 70px 70px;
  }
}

@media (max-width: 1199px) {
  .aboutus-new-main .about-discription .common-hd1 {
    font-size: 17px;
    line-height: 25px;
  }
  .aboutus-new-main .lft-right-section .common-hd,
  .aboutus-new-main .ourstory-main .common-hd {
    font-size: 26px;
    line-height: 28px;
  }
  .aboutus-new-main .about-option-main {
    margin-left: 0px;
    margin-right: 0px;
  }
  .aboutus-new-main .about-option-main li {
    padding: 25px 20px;
    font-size: 17px;
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  .aboutus-new-main .about-discription .common-hd1 {
    font-size: 14px;
    line-height: 20px;
  }
  .aboutus-new-main .ourstory-main .content-detail {
    margin-top: 15px;
  }
  .aboutus-new-main .ourstory-main .content {
    margin-top: -30%;
    padding: 130px 30px 30px 30px;
  }
  .aboutus-new-main .about-option-main li {
    width: 100%;
  }
  .aboutus-new-main .about-option-main li:not(:last-child):before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 250px;
    text-align: center;
    background-color: #9e371e;
    margin: 0 auto;
    height: 1px;
  }
  .aboutus-new-main .about-option-main li:last-child {
    padding-bottom: 0px;
  }
}

.com_list {
  list-style: disc;
  padding-left: 20px;
  width: 100%;
  float: left;
}
.com_list li {
  position: relative;
  margin-bottom: 15px;
}
.comm_list_msg {
  font-size: 14px;
  color: #2b2b2b;
  font-family: "Figtree", sans-serif;
  line-height: 16px;
}
.common-space {
  margin-bottom: 50px;
}
.common-space-top {
  margin-top: 50px;
}
.common-space1 {
  margin-bottom: 90px;
}
.common-space1-top {
  margin-top: 90px;
}
.common-space2 {
  margin-bottom: 30px;
}
.common-space2-top {
  margin-top: 30px;
}
.sup-center,
.sup-center:hover {
  color: #ffffff;
}
.dmd-disclaimer-link {
  text-decoration: underline;
  color: #9e371e;
  font-size: 10px;
  line-height: 14px;
}
.dmd-dis-msg {
  line-height: 14px;
}
.popup-cls #sb-site {
  height: 100vmax;
}
.popup-cls .pt80 {
  padding-top: 80px;
}
@media (max-width: 767px) {
  .common-space1 {
    margin-bottom: 70px;
  }
  .common-space1-top {
    margin-top: 70px;
  }
  .common-space {
    margin-bottom: 35px;
  }
  .common-space-top {
    margin-top: 35px;
  }
  .common-space2 {
    margin-bottom: 15px;
  }
  .common-space2-top {
    margin-top: 15px;
  }
  .common-space.mob-m0,
  .common-space1.mob-m0 {
    margin-bottom: 0px;
  }
  .common-subhd.common-space {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .phone-link {
    pointer-events: none;
  }
}

/*common banner*/
.common-banner .container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.common-banner .content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  width: 500px;
}
.common-banner .content p {
  margin-bottom: 0px;
  font-size: 17px;
  line-height: 23px;
}

.common-banner .thumb img {
  width: 100%;
}

.common-banner .common-hd {
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
}

.common-banner.left-text-banner .content {
  right: auto;
  left: 9px;
}
.common-banner.left-text-banner .content .btn,
.common-banner.right-text-banner .content .btn {
  margin-top: 25px;
}
.common-banner .text-white .common-hd,
.common-banner .text-white p,
.common-banner .text-white .btn_link {
  color: #ffffff;
}
.common-banner .text-green .common-hd,
.common-banner .text-green p {
  color: #2b2b2b;
}
.common-banner.left-text-banner .content .btn.btn-style1 {
  line-height: 18px;
  height: inherit;
}
.common-banner.right-text-banner .content .btn.btn-style1 {
  line-height: 18px;
  height: inherit;
}

.common-banner.center-text-banner .content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: auto;
  width: 700px;
}
.common-banner.center-text-banner.full-text-banner .content {
  width: 100%;
}
.common-banner.center-text-banner.full-text-banner .content p {
  margin-bottom: 10px;
}
.common-banner.center-text-banner.full-text-banner .content p:last-child {
  margin-bottom: 0px;
}

.common-banner .text-white .btn_link {
  margin-top: 20px;
  display: block;
}
.common-banner .text-white .btn_link:hover {
  color: #808381;
}

.gemologists-wrapper {
}

.gemologists-wrapper .content {
  background-color: #ffede4;
  /* background-color: #d5ebec; */
  padding: 50px 30px;
}

.gemologists-wrapper .grid__item-content {
  position: relative;
  z-index: 1;

  /* @media screen and (max-width: 400px) {
    p {
      font-size: 12px;
      line-height: 15px;
    }
  } */
}

/* @media screen and (max-width: 750px) {
  .gemologists-wrapper .medium-up--one-half {
    width: 50% !important;
    order: 2;
    
  }
  .gemologists-wrapper .grid__item-content {
    margin-right: -80px;
    width: 45%;
  }
} */

@media screen and (min-width: 750px) {
  .gemologists-wrapper .medium-up--one-half {
    /* width: 50% !important; */
    order: 2;
  }
  .gemologists-wrapper .grid__item-content {
    margin-right: -80px;
    width: 500px;
  }
}

.gemologists-wrapper .medium-up--one-half div {
  position: relative;
  padding-top: 120%;
  aspect-ratio: 88/93;
  height: 428px;
  width: 291px;

  @media screen and (min-width: 1024px) {
    height: 600px;
    width: 420px;
  }
}
.gemologists-wrapper .medium-up--one-half div img {
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 749px) {
  .gemologists-wrapper .medium-up--one-half {
    padding-left: 40px;
    display: flex;
    justify-content: end;
    img {
    }
  }
  /* .gemologists-wrapper {
    position: absolute;
    object-fit: cover;
    top: 0px;
    left: 0px;
    width: 90%;
    height: 600px;
  } */

  .gemologists-wrapper .grid__item-content {
    padding-left: 20px;
    top: -60px;
  }

  .gemologists-wrapper .content {
    max-width: 80%;
    width: 300px;
    padding: 40px 25px !important;
    margin-top: -45px;

    @media screen and (max-width: 450px) {
      max-width: 75%;
      width: 300px;
      padding: 15px 15px !important;
    }
  }
}

@media screen and (max-width: 350px) {
  .gemologists-wrapper .medium-up--one-half {
    display: block;
  }
}
.card-section img {
  width: 85%;
  height: 100%;
}
.inside-img {
  width: 78% !important;
  height: 400px !important;
  @media screen and (max-width: 992px) {
    height: 450px !important;
  }
  @media screen and (max-width: 550px) {
    height: 300px !important;
  }
}
@media screen and (max-width: 550px) {
  .card-section img {
    width: 70% !important;
    /* height: 50% !important;
    min-height: 50%; */
  }
}

@media screen and (max-width: 992px) {
  .card-section img {
    width: 100% !important;
    /* height: 50% !important;
    min-height: 50%; */
  }
  .card-section .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-section .content {
    order: 2;
  }
}
